import React from 'react'
import { graphql } from 'gatsby'
import './styles/blog.scss'
import Layout from '../components/Layout'
import ArticlePreview from '../components/Blog/ArticlePreview'
import AppStateProvider from '../state'
import BlogHero from 'src/components/Blog/BlogHero'
import { Container } from 'components/PageContainer'
import { BlogJsonLd } from 'gatsby-plugin-next-seo'

const BlogIndex = ({ data }) => {

  //const siteTitle = data.site.siteMetadata.subTitle

  const posts = data.allContentfulBlogPost.edges

  const post_list = posts.map(({ node }) => {
    return {
      headline: node.title,
    }
  })


  return (
    <AppStateProvider>
      <Layout navClasses="transparent" title="News and Blog posts | SwiftDoc">
        <BlogJsonLd
          url="https://swiftdoc.com/news"
          headline="News and Blog posts"
          posts={post_list}
          datePublished="2020-03-09T09:00:00+00:00"
          dateModified="2021-03-09T09:00:00+00:00"
          authorName="Richard McMahon"
          description="Blog and News posts about health and wellbeing"
        />
        <BlogHero pageTitle="SwiftDoc News" noBackground={true} />
        <Container>
          <div className="blogPageContainer">
            <div className="blogContainer">
              <div className="wrapper">
                <h2 className="section-headline">Recent articles</h2>
                <ul className="article-list">
                  {posts.map(({ node }) => {
                    return (
                      <li key={node.slug}>
                        <ArticlePreview article={node} />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </Layout>
    </AppStateProvider>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            gatsbyImageData(
              height: 196
              width: 350
              quality: 70
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`
