import React from 'react'
import { Container } from '../../PageContainer'
import './blogHero.scss'
import parse from 'html-react-parser'

function BlogHero (props) {
  return (
    <div className="background">
      <div className="gradientBackground">
        <Container className="Container" id="hero-container">
          <h1 className="blogHeroTitle">{parse(props.pageTitle)}</h1>
        </Container>
        <div
          style={{
            height: 150,
            overflow: 'hidden',
            backgroundColor: 'rgba(255,255,255,0)',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            zIndex: '1',
            marginBottom: '-3px'
          }}
        >
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: '100%', width: '100%', position: 'relative' }}
          >
            <path
              d="M0.00,49.98 C149.99,150.00 353.83,62.66 501.12,22.20 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: 'none', fill: props.noBackground ? '#F1F6FE' :'#fff', background: 'transparent' }}
            ></path>
          </svg>
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              bottom: '10%',
              left: 0,
            }}
          >
            <path
              d="M0.00,49.98 C149.99,150.00 353.83,62.66 501.12,22.20 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: 'none', fill: props.noBackground ? '#F1F6FE' : 'rgba(255,255,255,0.75)', background: 'transparent'}}
            ></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default BlogHero