import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import ScheduleIcon from '@material-ui/icons/Schedule'

import './articlePreview.scss'

const ArticlePreview = ({ article }) => {
  return (
    <div className="preview">
      <GatsbyImage alt="" image={article.heroImage.gatsbyImageData} />
      <h3 className="previewTitle">
        <Link activeClassName="previewLink" to={`/${article.slug}`}>
          {article.title}
        </Link>
      </h3>
      <div className="previewSmall">
      <ScheduleIcon /><small>{article.publishDate}</small>
      </div>
     
    </div>
  )
}
export default ArticlePreview
